import React from "react"
import { BASAPP_ALT } from "../../../constants/Constants"

const image = require("../../../images/basapp/map.png")

const MapImage = props => {
  return <img src={image} alt={BASAPP_ALT} {...props} />
}

export default MapImage
