import React from "react"
import IntlText from "../../components/common/intl-text-2"
import Text from "../../components/common/text"
import AndroidIcon from "../../components/icons/AndroidIcon"
import AppleIcon from "../../components/icons/AppleIcon"
import WebIcon from "../../components/icons/WebIcon"
import Layout from "../../components/layout"
import AccountImage from "../../components/projects/basapp-images/account"
import AccountMobileImage from "../../components/projects/basapp-images/account-mobile"
import BackgroundImage from "../../components/projects/basapp-images/background"
import ConfigImage from "../../components/projects/basapp-images/config"
import ConfigMobileImage from "../../components/projects/basapp-images/config-mobile"
import DashboardImage from "../../components/projects/basapp-images/dashboard"
import DashboardMobileImage from "../../components/projects/basapp-images/dashboard-mobile"
import EmergencyImage from "../../components/projects/basapp-images/emergency"
import EmergencyMobileImage from "../../components/projects/basapp-images/emergency-mobile"
import IconCybImage from "../../components/projects/basapp-images/icon-cyb"
import LogoCybImage from "../../components/projects/basapp-images/logo-cyb"
import MapImage from "../../components/projects/basapp-images/map"
import MapMobileImage from "../../components/projects/basapp-images/map-mobile"
import ScratchImage from "../../components/projects/basapp-images/scratch"
import Screenshot1Image from "../../components/projects/basapp-images/screenshot-1"
import Screenshot2Image from "../../components/projects/basapp-images/screenshot-2"
import Screenshot3Image from "../../components/projects/basapp-images/screenshot-3"
import ProjectContainer from "../../components/projects/project-container"
import ProjectContent from "../../components/projects/project-content"
import ProjectIntro from "../../components/projects/project-intro"

const tags = ["Product Building", "UX / UI", "Frontend", "Mobile", "Backend"]
const stores = [
  {
    store: "android",
    icon: <AndroidIcon />,
    link: "https://play.google.com/store/apps/details?id=com.basapp.app",
  },
  {
    store: "apple",
    icon: <AppleIcon />,
    link: "https://apps.apple.com/ar/app/basapp/id1234725969",
  },
  {
    store: "web",
    icon: <WebIcon />,
    link: "http://basapp.com.ar",
  },
]
const technologies = "NodeJS, Angular, Ionic"

const HowWeHelp = () => {
  return (
    <div className="row how-we-help">
      <div className="col-12 col-lg-6 left-column">
        <IntlText id="basapp.body2" component="h1" />
      </div>
      <div className="col-12 col-lg-6 right-column">
        <IntlText id="basapp.body3" component="h2" />
      </div>
    </div>
  )
}

const WhatWeDo = () => {
  return (
    <div className="row what-we-do">
      <div className="col-12 col-md-6 col-lg-3">
        <Text id="technologies" />
        <Text>
          <strong>{technologies}</strong>
        </Text>
      </div>
      <div className="col-12 col-md-6 col-lg-3">
        <Text id="platforms" />
        <Text>
          <IntlText id="basapp.platforms" component="strong" />
        </Text>
      </div>
      <div className="col-12 col-md-6 col-lg-3">
        <Text id="services" />
        <Text>
          <IntlText id="basapp.services" component="strong" />
        </Text>
      </div>
      <div className="col-12 col-md-6 col-lg-3">
        <Text id="projectContent.explore" />
        <div className="store-icons">
          {stores.map((value, key) => (
            <a
              key={key}
              href={value.link}
              target="_blank"
              rel="noopener noreferrer"
              title={value.store}
            >
              {value.icon}
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}

const LogoRedesign = () => {
  return (
    <div className="project-redesign">
      <div className="row">
        <div className="col-12 col-md-6 margin-vertical-40-mobile">
          <LogoCybImage className="redesign-image" />
        </div>
        <div className="col-12 col-md-6 margin-vertical-40-mobile">
          <IconCybImage className="redesign-image" />
        </div>
      </div>
    </div>
  )
}

const Screenshots = () => {
  return (
    <div className="screenshots-container basapp">
      <div className="images">
        <Screenshot1Image className="screenshot-img" />
        <Screenshot2Image className="screenshot-img" />
        <Screenshot3Image className="screenshot-img" />
      </div>
    </div>
  )
}

const BasappPage = () => {
  return (
    <Layout
      pageTitle="Projects"
      title="Basapp´s  software development challenge - Sysgarage"
      description="Check out the software development project for Basapp that our company has successfully tackled. ​Let us show you how we can help!"
      hideHeader
      noMargin
      hideContact
    >
      <ProjectContainer>
        <div className="sg-container">
          <ProjectIntro
            tags={tags}
            logo={<LogoCybImage className="logo-image" />}
            textId="basapp.body1"
            background={<BackgroundImage className="background-image" />}
          />
          <HowWeHelp />
          <WhatWeDo />
        </div>
        <ProjectContent
          titleId="basapp.body4"
          descriptionId="basapp.body5"
          image={<ScratchImage />}
          imageMobile={<ScratchImage />}
        />
        <ProjectContent
          titleId="basapp.body6"
          descriptionId="basapp.body7"
          image={<ConfigImage />}
          imageMobile={<ConfigMobileImage />}
        />
        <ProjectContent
          titleId="basapp.body8"
          descriptionId="basapp.body9"
          image={<AccountImage />}
          imageMobile={<AccountMobileImage />}
        />
        <ProjectContent
          titleId="basapp.body10"
          descriptionId="basapp.body11"
          image={<MapImage />}
          imageMobile={<MapMobileImage />}
        />
        <ProjectContent
          titleId="basapp.body12"
          descriptionId="basapp.body13"
          image={<DashboardImage />}
          imageMobile={<DashboardMobileImage />}
        />
        <ProjectContent titleId="basapp.body14">
          <LogoRedesign />
        </ProjectContent>
        <ProjectContent
          titleId="basapp.body15"
          descriptionId="basapp.body16"
          image={<EmergencyImage />}
          imageMobile={<EmergencyMobileImage />}
        />
        <ProjectContent titleId="basapp.body17">
          <Screenshots />
          <div className="blank-space" />
        </ProjectContent>
      </ProjectContainer>
    </Layout>
  )
}

export default BasappPage
