import React from "react"
import { BASAPP_ALT } from "../../../constants/Constants"

const image = require("../../../images/basapp/logo-cyb.png")

const LogoCybImage = props => {
  return <img src={image} alt={BASAPP_ALT} {...props} />
}

export default LogoCybImage
