import React from "react"
import { BASAPP_ALT } from "../../../constants/Constants"

const image = require("../../../images/basapp/screenshot3.png")

const Screenshot3Image = props => {
  return <img src={image} alt={BASAPP_ALT} {...props} />
}

export default Screenshot3Image
